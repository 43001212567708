"use client";
import React, { CSSProperties } from "react";
import { usePathname } from "next/navigation";
import Image from "next/image";
import { HeroSectionProps } from "@/types";
import Button from "@/components/Button";

export const HeroSection: React.FC<HeroSectionProps> = ({ bgImage, minHeight = 450, children }) => {
  const pathname = usePathname();
  const style: CSSProperties = {
    backgroundImage: `linear-gradient(90deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.3) 100%)`,
    minHeight: minHeight,
    boxSizing: "content-box",
  };

  return (
    <section
      style={style}
      className={`flex bg-home-hero bg-cover bg-no-repeat bg-center`}
    >
      <Image
        src={bgImage}
        fill={true}
        className={"-z-10 object-cover w-full"}
        alt={"Hero Image Background"}
        priority={true}
      />
      <div className="container gap-y-6 m-auto flex flex-col content-center items-center py-12 sm:py-24">
        <div className="w-11/12 lg:flex justify-center items-center flex-col mb-5 sm:mb-10">
          {children}
        </div>
        <div className="flex justify-center items-center">
          {pathname !== "/contact-us" ? (
            <Button href={`/contact-us#bookAClass`} passHref={true} scroll={false} type={"button"}>
              Book a Class
            </Button>
          ) : (
            <Button href={`/about-kenpo-karate`} type={"button"}>
              About Kenpo Karate
            </Button>
          )}
        </div>
      </div>
    </section>
  );
};
