import(/* webpackMode: "eager" */ "/vercel/path0/components/Faq.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeroSection"] */ "/vercel/path0/components/layout/HeroSection.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/fist-sparks.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/karate-hero-dark.webp");
