import { ClientConfig, createClient } from "next-sanity";

export const projectId: string = process.env.NEXT_PUBLIC_SANITY_PROJECT_ID!;
export const dataset: string = process.env.NEXT_PUBLIC_SANITY_DATASET!;

let date: string = new Date().toLocaleDateString("en-gb").split("/").reverse().join("-");

const apiVersion: string = process.env.NEXT_PUBLIC_SANITY_API_VERSION || date;

const config: ClientConfig = {
  projectId,
  dataset,
  apiVersion,
  useCdn: true,
};

export const client = createClient(config);