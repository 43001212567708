import React from "react";
import Link from "next/link";
import { ButtonProps } from "@/types";

const Button: React.FC<ButtonProps> = ({
                                         children,
                                         href = "/",
                                         replace = false,
                                         scroll = true,
                                         type,
                                         passHref,
                                         prefetch = false,
                                       }) => {
  return (
    <Link href={href} replace={replace} scroll={scroll} passHref={passHref} prefetch={prefetch}>
      <button
        type={type}
        className={`btn`}
      >
        {children}
      </button>
    </Link>
  );
};

export default Button;
