"use client";
import React from "react";
import { Disclosure, DisclosureButton, DisclosurePanel, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { PortableText } from "@portabletext/react";
import { RichTextComponents } from "@/components/RichTextComponents";
import { FaqType } from "@/schemas/faq";

export default function Faq({ _key, question, answer }: FaqType) {
  if (!answer) {
    return null;
  }
  return (
    <div key={_key} itemScope itemType="https://schema.org/FAQPage" className="md:w-8/12 w-10/12 mb-2">
      <div className="mx-auto w-full rounded-sm bg-white p-2">
        <Disclosure>
          {({ open }) => (
            <div itemProp="mainEntity" itemScope itemType="https://schema.org/Question">
              <DisclosureButton
                className="flex w-full justify-between rounded-lg px-4 py-2 text-left text-lg font-medium text-red-dark-brand hover:bg-red-brand-dark/30 focus:outline-none focus-visible:ring focus-visible:ring-red-brand-dark focus-visible:ring-opacity-75">
                <span itemProp="name">{question}</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-5 w-5 text-red-dark-brand`}
                />
              </DisclosureButton>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <DisclosurePanel className="px-4 pt-4 pb-2 text-md text-gray-800 prose min-w-full">
                  <div
                    itemScope
                    itemType="https://schema.org/Answer"
                    itemProp="acceptedAnswer"
                    className="prose max-w-none prose:text-gray-800 prose-headings:font-heading prose-h1:text-4xl prose-h2:text-4xl prose-h3:text-3xl prose-h4:text-3xl prose-headings:tracking-tight prose-headings:font-normal">
                    <PortableText value={answer} components={RichTextComponents} />
                  </div>
                </DisclosurePanel>
              </Transition>
            </div>
          )}
        </Disclosure>
      </div>
    </div>
  );
}
