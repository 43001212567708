import Image from "next/image";
import Link from "next/link";
import urlFor from "../lib/urlFor";

export const RichTextComponents = {
  types: {
    image: ({ value }: any) => (
      <div className={"relative w-full h-96 m-10 mx-auto"}>
        <Image
          className={"object-contain"}
          src={urlFor(value).url()}
          alt="Blog Post Image"
          fill
        />
      </div>
    ),
    // callToAction: ({value, isInline}) =>
    //     isInline ? (
    //         <a href={value.url}>{value.text}</a>
    //     ) : (
    //         <div className="callToAction">{value.text}</div>
    //     ),
  },
  list: {
    bullet: ({ children }: any) => (
      <ul className={"ml-0 pb-2 list-disc space-y-1 marker:text-brandGreen-700"}>{children}</ul>
    ),
    number: ({ children }: any) => (
      <ol className={"mt-lg list-decimal"}>{children}</ol>
    ),
  },
  block: {
    h1: ({ children }: any) => (
      <h1>{children}</h1>
    ),
    h2: ({ children }: any) => (
      <h2>{children}</h2>
    ),
    h3: ({ children }: any) => (
      <h3>{children}</h3>
    ),
    h4: ({ children }: any) => (
      <h4>{children}</h4>
    ),
    h5: ({ children }: any) => (
      <h5>{children}</h5>
    ),
    h6: ({ children }: any) => (
      <h6>{children}</h6>
    ),
    strong: ({ children }: any) => (
      <strong className={"text-white"}>{children}</strong>
    ),
    blockquote: ({ children }: any) => (
      <blockquote className={"border-l-black border-l-4 pl-5 py-5 my-5"}>{children}</blockquote>
    ),
  },
  marks: {
    link: ({ children, value }: any) => {
      const rel = !value.href.startsWith("/") ? "noreferrer noopener" : undefined;
      return (
        <Link
          href={value.href}
          rel={rel}
          className={"underline decoration-yellow-400 hover:decoration-block"}
        >
          {children}
        </Link>
      );
    },
  },
};